import { useState } from 'react';
import ShareButton from './Button';
import Modal from './Modal';
import ShareArea from './ShareArea';
import { Title, Text } from './styles';

interface IShare {
  transparent?: boolean;
}

const Share: React.FC<IShare> = ({ transparent }: IShare) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <ShareButton
        onClick={() => setOpenModal(true)}
        transparent={transparent}
      />
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Title>Compartilhe o conteúdo com a sua rede!</Title>
        <Text>Compartilhe conhecimento!</Text>
        <ShareArea />
      </Modal>
    </>
  );
};

export default Share;
