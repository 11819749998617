import { createContext, useContext, useState } from 'react';

import { ITrack } from '@models/player.interface';

interface IPodcastContextProps {
  color: string;
  setColor: (color: string) => void;
  isPlaying: boolean;
  setIsPlaying: (boolean) => void;
  showPlayer: boolean;
  setShowPlayer: (boolean) => void;
  currentTime: number;
  setCurrentTime: (number) => void;
  playbackRate: number;
  setplaybackRate: (number) => void;
  trackIndex: number;
  setTrackIndex: (number) => void;
  trackQueue: ITrack[];
  setTrackQueue: (queue: ITrack[]) => void;

  onPlay: () => void;
  setOnPlay: (fn: any) => void;
  onPause: () => void;
  setOnPause: (fn: any) => void;
  onNext: () => void;
  setOnNext: (fn: any) => void;
  onPrev: () => void;
  setOnPrev: (fn: any) => void;
  onClose: () => void;
  setOnClose: (fn: any) => void;

  disableNextAndPrev: boolean;
  setDisableNextAndPrev: (boolean) => void;
}

const PodcastContext = createContext<IPodcastContextProps>(
  {} as IPodcastContextProps,
);

const PodcastProvider: React.FC = ({ children }) => {
  const [color, setColor] = useState('');
  const [disableNextAndPrev, setDisableNextAndPrev] = useState(false);

  const [onPlay, setOnPlay] = useState(undefined);
  const [onPause, setOnPause] = useState(undefined);
  const [onPrev, setOnPrev] = useState(undefined);
  const [onNext, setOnNext] = useState(undefined);
  const [onClose, setOnClose] = useState(undefined);

  const [isPlaying, setIsPlaying] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [playbackRate, setplaybackRate] = useState(1);
  const [trackIndex, setTrackIndex] = useState(0);
  const [trackQueue, setTrackQueue] = useState<ITrack[]>();

  return (
    <PodcastContext.Provider
      value={{
        isPlaying,
        setIsPlaying,
        showPlayer,
        setShowPlayer,
        currentTime,
        setCurrentTime,
        playbackRate,
        setplaybackRate,
        trackIndex,
        setTrackIndex,
        trackQueue,
        setTrackQueue,
        onPlay,
        setOnPlay,
        onPause,
        onNext,
        onPrev,
        setOnClose,
        setOnNext,
        setOnPause,
        setOnPrev,
        onClose,
        color,
        setColor,
        disableNextAndPrev,
        setDisableNextAndPrev,
      }}
    >
      {children}
    </PodcastContext.Provider>
  );
};

function usePodcastContext(): IPodcastContextProps {
  const context = useContext(PodcastContext);
  if (!context) {
    throw new Error('usePodcastContext must be used within a PodcastProvider');
  }
  return context;
}
export { usePodcastContext, PodcastProvider };
